import { Button, Theme, useMediaQuery } from '@borda/cat-mui';
import { CatIconBaseProps } from '@borda/cat-icons';
import { CatIconButton, CatTooltip, CatTypography } from '@borda/cat-ui';
import { NavLink, useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  disabled?: boolean;
  href?: string;
  icon?: (props: CatIconBaseProps) => React.ReactElement;
  title: string;
};

function NavItem({ className, disabled = false, href, icon: Icon, title, ...rest }: Props) {
  const location = useLocation();
  const showLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const isMatched = useMemo(() => {
    const path = location.pathname.split('/')[1];
    const hrefPath = href.split('/')[1];
    return path === hrefPath;
  }, [location.pathname, href]);

  return (
    <div {...rest} className={clsx(className, 'flex items-center')} key={title}>
      {showLarge ? (
        <NavLink
          className={({ isActive }) =>
            clsx({
              'bg-lightGrey/80 shadow-1 opacity-80': isActive,
              'pointer-events-none': disabled,
              'w-full rounded-[20px]  opacity-60 ': true
            })
          }
          to={href}
        >
          <Button
            className="!h-7 !w-full justify-start normal-case"
            disabled={disabled}
            startIcon={Icon && <Icon className="!text-2xl" color="darkGrey" hoverable={false} />}
          >
            <CatTypography noWrap variant="body2">
              {title}
            </CatTypography>
          </Button>
        </NavLink>
      ) : (
        <CatTooltip placement="right" title={title}>
          <NavLink className={disabled && 'pointer-events-none'} to={href}>
            <CatIconButton className="opacity-80" disabled={disabled}>
              <Icon alwaysHovered={isMatched} color="darkGrey" />
            </CatIconButton>
          </NavLink>
        </CatTooltip>
      )}
    </div>
  );
}

export default NavItem;
