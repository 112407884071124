import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const Contracts = delayedLazy(() => import('views/Contracts/Contracts'));
const CreateGeneralInformation = delayedLazy(
  () => import('views/Contracts/Modify/GeneralInformation/CreateGeneralInformation')
);
const UpdateGeneralInformation = delayedLazy(
  () => import('views/Contracts/Modify/GeneralInformation/UpdateGeneralInformation')
);
const Metrics = delayedLazy(() => import('views/Contracts/Modify/Metrics/Metrics'));
const Summary = delayedLazy(() => import('views/Contracts/Modify/Summary/Summary'));

const ContractDetail = delayedLazy(() => import('views/Contracts/ContractSummaryPage'));

export default function renderContractRoutes() {
  return (
    <Route element={<SuspenseOutlet />} path="contract">
      <Route
        element={
          <RouteGuard authKey="contracts">
            <Outlet />
          </RouteGuard>
        }
        path="contracts"
      >
        <Route element={<Navigate to="list" />} index />
        <Route element={<Contracts />} path="list">
          <Route element={<ContractDetail />} path=":id" />
        </Route>
        <Route
          element={
            <RouteGuard authKey="contracts">
              <Outlet />
            </RouteGuard>
          }
          path="add"
        >
          <Route element={<CreateGeneralInformation />} path="generalInformation" />
          <Route
            element={<UpdateGeneralInformation mode="add" />}
            path=":contractId/generalInformation"
          />
          <Route element={<Metrics mode="add" />} path=":contractId/metrics" />
          <Route element={<Summary mode="add" />} path=":contractId/summary" />
        </Route>
        <Route
          element={
            <RouteGuard authKey="contracts">
              <Outlet />
            </RouteGuard>
          }
          path="edit"
        >
          <Route
            element={<UpdateGeneralInformation mode="edit" />}
            path=":contractId/generalInformation"
          />
          <Route element={<Metrics mode="edit" />} path=":contractId/metrics" />
          <Route element={<Summary mode="edit" />} path=":contractId/summary" />
        </Route>
      </Route>
      <Route element={<Navigate replace to="contracts" />} index />
    </Route>
  );
}
