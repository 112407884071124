import { combineReducers } from '@reduxjs/toolkit';
import accessPointReducer from './accessPoint/index';
import locationReducer from './location/slice';

const locationsReducer = combineReducers({
  accessPoints: accessPointReducer,
  locations: locationReducer
});

export default locationsReducer;
