import { ERROR401 } from 'routes/constant-route';
import { history } from './history';
import { silentRefresh } from 'store/slices/session';
import axios, { AxiosInstance } from 'axios';
import store from 'store';

type RefreshCallback = () => void;
let isRefreshing = false;
let refreshSubscribers: RefreshCallback[] = [];

const subscribeTokenRefresh = (cb: RefreshCallback) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = () => {
  refreshSubscribers.forEach((cb) => cb());
  refreshSubscribers = [];
};

const addAuthorizationRefreshInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status !== 401) {
        throw error;
      }

      if (originalRequest.retry) {
        history.push(ERROR401);
        originalRequest.retry = false;
        throw error;
      }

      // Wait if some other request is refreshing
      if (isRefreshing) {
        await new Promise<void>((resolve) => {
          subscribeTokenRefresh(() => {
            originalRequest.headers.Authorization =
              axiosInstance.defaults.headers.common.Authorization;
            resolve();
          });
        });

        // transformResponse breaks response, data won't be json
        return axiosInstance({ ...originalRequest, transformResponse: undefined });
      }

      // Refresh logic
      isRefreshing = true;
      originalRequest.retry = true;

      try {
        await store.dispatch(silentRefresh(false, true));

        isRefreshing = false;
        onRefreshed();

        originalRequest.headers.Authorization = axiosInstance.defaults.headers.common.Authorization;

        // transformResponse breaks response, data won't be json
        return await axiosInstance({ ...originalRequest, transformResponse: undefined });
      } catch (refreshError) {
        isRefreshing = false;
        refreshSubscribers = [];

        throw refreshError;
      }
    }
  );
};

export const initAxiosInterceptors = () => {
  addAuthorizationRefreshInterceptor(axios);
};
