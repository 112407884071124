import { CatTypography } from '@borda/cat-ui';
import { CloseIconButton } from 'packages/cat-wrappers';
import { useCatTranslation } from 'utils/localization';
import ActionBar from './ActionBar';

type Props = {
  onClose: () => void;
  total: number;
};

function Header(props: Props) {
  const { onClose, total } = props;
  const { t } = useCatTranslation();

  return (
    <div className="grid gap-6 ltr:pr-4 rtl:pl-4">
      <div className="flex justify-between">
        <CatTypography variant="h2">{t('notifications.header')}</CatTypography>
        <CloseIconButton onClick={onClose} />
      </div>
      <ActionBar total={total} />
    </div>
  );
}

export default Header;
