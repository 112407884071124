import { MainCategory } from 'store/slices/session';
import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { WorkType } from 'store/slices/common/types';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const Breakdown = delayedLazy(() => import('views/TaskConfigurations/Breakdown/Breakdown'));
const Protection = delayedLazy(() => import('views/TaskConfigurations/Protection/Protection'));

export default function renderTaskConfigurationRoutes(mainCategories: MainCategory[]) {
  return (
    <Route
      element={
        <RouteGuard
          authKey="taskConfiguration"
          workTypes={['breakdown', 'maintenance', 'calibration']}
        >
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="task-configuration"
    >
      <Route
        element={
          <RouteGuard workTypes={['breakdown']}>
            <Breakdown />
          </RouteGuard>
        }
        path="breakdown"
      />
      <Route
        element={
          <RouteGuard workTypes={['maintenance']}>
            <Protection protectionKey="maintenance" />
          </RouteGuard>
        }
        path="maintenance"
      />
      <Route
        element={
          <RouteGuard workTypes={['calibration']}>
            <Protection protectionKey="calibration" />
          </RouteGuard>
        }
        path="calibration"
      />
      <Route element={<Navigate replace to={calculateDefaultNavigation(mainCategories)} />} index />
    </Route>
  );
}

const defaultWorkTypeOrder: WorkType[] = ['breakdown', 'maintenance', 'calibration'];
function calculateDefaultNavigation(mainCategories: MainCategory[]) {
  let authorizedNavigation = '';
  if (isArrayNullOrEmpty(mainCategories)) {
    return authorizedNavigation;
  }

  authorizedNavigation = defaultWorkTypeOrder.find((wt) => {
    const isAuthorizedForWorkType = mainCategories.find((c) => c.workTypes.includes(wt));
    return isAuthorizedForWorkType ? wt : undefined;
  });

  return authorizedNavigation;
}
