import { CatTypography, useLocalizationHelpers } from '@borda/cat-ui';
import { Link } from 'react-router-dom';
import { NotificationListItem } from 'store/slices/notifications/list/types';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { useCatTranslation } from 'utils/localization';
import { useMarkNotificationAsReadByIdMutation } from 'views/Notifications/queries';
import BranchesList from './BranchesList';

type Props = {
  notification: NotificationListItem;
  onClose: () => void;
};

function RightPanel(props: Props) {
  const { notification, onClose } = props;
  const { t } = useCatTranslation('translation', { keyPrefix: 'notifications.card' });

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadByIdMutation();

  const { formatDateAndTime } = useLocalizationHelpers();

  const getTimeText = () => {
    const minDiff = differenceInMinutes(new Date(), new Date(notification.createdDate));
    if (minDiff < 1) {
      return t('time_info.now');
    }

    if (minDiff >= 1 && minDiff < 60) {
      return t('time_info.minute', { count: minDiff });
    }

    const hourDiff = differenceInHours(new Date(), new Date(notification.createdDate));
    if (hourDiff < 25) {
      return t('time_info.hour', { count: hourDiff });
    }

    return formatDateAndTime(notification.createdDate);
  };

  const handleLinkClick = async () => {
    if (!notification.isRead) {
      await markNotificationAsRead(notification.id);
    }

    onClose();
  };

  return (
    <div className="grid gap-2 p-3">
      <CatTypography className="truncate font-bold opacity-80" variant="body1">
        {notification.title}
      </CatTypography>
      {notification.content?.split('\n').map((line) => (
        <CatTypography className="opacity-80" key={line} variant="body2">
          {line}
        </CatTypography>
      ))}
      <BranchesList branchIds={notification.branchIds} />
      <div className="flex ltr:mr-2 rtl:ml-2">
        {notification.webLink && (
          <CatTypography
            className="text-blue min-w-fit flex-auto font-bold underline opacity-80"
            variant="body2"
          >
            <Link onClick={handleLinkClick} to={notification.webLink}>
              {t('tap_to_see_text')}
            </Link>
          </CatTypography>
        )}
        <CatTypography className="flex w-full place-content-end opacity-60" variant="caption">
          {getTimeText()}
        </CatTypography>
      </div>
    </div>
  );
}

export default RightPanel;
