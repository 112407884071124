import {
  AdditionalPermissionType,
  PatientRoleType,
  RoleType
} from '../userManagement/users/common/roleRelated';

export type AuthKeys =
  | 'assetConfiguration'
  | 'assetConfiguration_MainCategoryOperations'
  | 'assetDashboard'
  | 'assetDelete'
  | 'assetDetail_CategoryBrandModelCreate'
  | 'assetDetail_ActivePassiveOperations'
  | 'assetList'
  | 'assetList_ActivePassiveOperations'
  | 'assetTransfer'
  | 'attributeConfiguration'
  | 'attributeConfiguration_Asset'
  | 'attributeConfiguration_Location'
  | 'attributeConfiguration_Patient'
  | 'attributeConfiguration_User'
  | 'contracts'
  | 'contractSummary'
  | 'contractActions'
  | 'contractAdd'
  | 'contractDisassociate'
  | 'contractsSummary_CostAndDocumentsDisplay'
  | 'integration'
  | 'locationManagement'
  | 'mainCategoryEdit'
  | 'metricsConfiguration'
  | 'planDisassociate'
  | 'tagConfiguration'
  | 'taskConfiguration'
  | 'taskDetail_AssignPersonnel'
  | 'taskDetail_ChangePersonnelIfAssigned'
  | 'taskDetail_DeleteNotes'
  | 'taskList'
  | 'taskList_Filter_PersonSelect'
  | 'taskOpen_AssignPersonnel'
  | 'userManagement'
  | 'wfcList'
  | 'patient'
  | 'patientDetail'
  | 'patientList_Actions'
  | 'patientDetail_DeleteNotes'
  | 'patientEdit'
  | 'plan'
  | 'planAssociate'
  | 'realtimeMap_Asset'
  | 'realtimeMap_Patient'
  | 'assetImport'
  | 'planActions'
  | 'realtimeTrackingAllAssets'
  | 'userManagement_addGeneralAdmin'
  | 'medicalUnits'
  | 'myExports'
  | 'notificationConfiguration'
  | 'workflowsAndActions';

type allRoleType = (RoleType | AdditionalPermissionType | PatientRoleType)[];

export const authMap: Record<AuthKeys, allRoleType> = {
  assetConfiguration: ['Admin'],
  assetConfiguration_MainCategoryOperations: [],
  assetDashboard: [],
  assetDelete: [],
  assetDetail_ActivePassiveOperations: ['Admin'],
  assetDetail_CategoryBrandModelCreate: ['Admin'],
  assetImport: ['Technician', 'Manager', 'Admin'],
  assetList: ['Technician', 'Manager', 'Admin'],
  assetList_ActivePassiveOperations: ['Admin'],
  assetTransfer: ['Admin'],
  attributeConfiguration: ['Admin', 'PatientAdmin', 'LocationAdmin', 'UserAdmin'],
  attributeConfiguration_Asset: ['Admin'],
  attributeConfiguration_Location: ['LocationAdmin'],
  attributeConfiguration_Patient: ['PatientAdmin'],
  attributeConfiguration_User: ['UserAdmin'],
  contractActions: ['Admin'],
  contractAdd: ['Admin'],
  contractDisassociate: ['Manager', 'Admin'],
  contractSummary: ['Technician', 'Manager', 'Admin'],
  contracts: ['Technician', 'Manager', 'Admin'],
  contractsSummary_CostAndDocumentsDisplay: ['Admin'],
  integration: [],
  locationManagement: ['LocationAdmin'],
  mainCategoryEdit: [],
  medicalUnits: ['PatientAdmin'],
  metricsConfiguration: ['Admin'],
  myExports: ['Technician', 'Manager', 'Admin'],
  notificationConfiguration: [],
  patient: [
    'HospitalMonitorization',
    'ClinicalUser',
    'ClinicalManager',
    'ReportExecutive',
    'PatientAdmin'
  ],
  patientDetail: [
    'HospitalMonitorization',
    'ClinicalUser',
    'ClinicalManager',
    'ReportExecutive',
    'PatientAdmin'
  ],
  patientDetail_DeleteNotes: ['PatientAdmin'],
  patientEdit: ['ClinicalManager', 'ReportExecutive', 'PatientAdmin'],
  patientList_Actions: ['ClinicalUser', 'ClinicalManager', 'ReportExecutive', 'PatientAdmin'],
  plan: ['Technician', 'Manager', 'Admin'],
  planActions: ['Technician', 'Manager', 'Admin'],
  planAssociate: ['Technician', 'Manager', 'Admin'],
  planDisassociate: ['Technician', 'Manager', 'Admin'],
  realtimeMap_Asset: ['Admin', 'Executive', 'Manager', 'Technician', 'RequestOnly'],
  realtimeMap_Patient: [
    'HospitalMonitorization',
    'ClinicalUser',
    'ClinicalManager',
    'ReportExecutive',
    'PatientAdmin'
  ],
  realtimeTrackingAllAssets: ['RequestOnly', 'Executive'],
  tagConfiguration: ['Technician', 'Admin', 'Manager'],
  taskConfiguration: ['Admin'],
  taskDetail_AssignPersonnel: ['Manager', 'Admin'],
  taskDetail_ChangePersonnelIfAssigned: ['Technician'],
  taskDetail_DeleteNotes: ['Admin'],
  taskList: ['Technician', 'Manager', 'Admin'],
  taskList_Filter_PersonSelect: ['Manager', 'Admin'],
  taskOpen_AssignPersonnel: ['Manager', 'Admin'],
  userManagement: ['UserAdmin'],
  userManagement_addGeneralAdmin: [],
  wfcList: ['Manager', 'Admin'],
  workflowsAndActions: []
};
