import { KeyPrefixesWithKeys } from './types/trans-keys-with-prefixes';
import { TFunction } from './common';
import { UseTranslationOptions, useTranslation as useTranslationOriginal } from 'react-i18next';
import i18next from 'utils/i18n';

export const useCatTranslation = <KPrefix extends keyof KeyPrefixesWithKeys = undefined>(
  translation?: 'translation',
  opts?: UseTranslationOptions<KPrefix>
) => {
  const { t: tOriginal, ...rest } = useTranslationOriginal(translation, opts);

  const t: TFunction<KPrefix> = (key, args) => tOriginal(key, args) as string;

  return { t, ...rest };
};

export const tCat: TFunction<undefined> = (key, args) => i18next.t(key, args) as string;
