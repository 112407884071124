import { Branch } from 'store/slices/branches';
import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const Locations = delayedLazy(() => import('views/Locations/Locations'));
const AccessPoints = delayedLazy(() => import('views/Locations/AccessPoints/AccessPoints'));
const MapSettingsPage = delayedLazy(() => import('views/Locations/MapSettings/MapSettingsPage'));
const CctvAdd = delayedLazy(() => import('views/Locations/Cctv/CctvAdd/CctvAdd'));
const CctvDetail = delayedLazy(() => import('views/Locations/Cctv/CctvDetail/CctvDetail'));

export default function renderLocationRoutes(rtlsEnabledBranches: Branch[]) {
  return (
    <Route
      element={
        <RouteGuard authKey="locationManagement">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="location"
    >
      <Route element={<Locations />} path="locations" />
      <Route
        element={
          <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <AccessPoints />
          </RouteGuard>
        }
        path="access-points"
      />
      <Route
        element={
          <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <CctvAdd />
          </RouteGuard>
        }
        path="cctv/cctv_add"
      />
      <Route
        element={
          <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <CctvDetail />
          </RouteGuard>
        }
        path="cctv/:id"
      />
      <Route
        element={
          <RouteGuard hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <MapSettingsPage />
          </RouteGuard>
        }
        path="map-settings"
      />
      <Route element={<Navigate replace to="locations" />} index />
    </Route>
  );
}
