import { CatPopover } from '@borda/cat-ui';
import { NotificationListItem } from 'store/slices/notifications/list/types';
import { PopupState, bindPopover } from 'material-ui-popup-state/hooks';
import { useInfiniteNotificationListQuery } from './queries';
import { useInfiniteQueryItems } from 'utils/react-query';
import { withDialogWrapper } from 'packages/cat-wrappers/hooks';
import Content from './Content/Content';
import Header from './Header/Header';

type Props = {
  onClose?: () => void;
  open: boolean;
  popupState: PopupState;
};

function NotificationPopover(props: Props) {
  const { onClose, open, popupState } = props;

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isPending: notificationsPending,
    isSuccess
  } = useInfiniteNotificationListQuery();

  const { items: allNotifications, total } = useInfiniteQueryItems<NotificationListItem>(
    data,
    isSuccess,
    'id'
  );

  return (
    <CatPopover
      {...bindPopover(popupState)}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={open}
      PaperProps={{ className: 'overflow-y-hidden flex w-full' }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top'
      }}
      width="512px"
    >
      <div className="grid w-full grid-rows-[1fr_minmax(auto,_600px)] gap-4 px-2 pt-4 ltr:pl-4 rtl:pr-4">
        <Header onClose={onClose} total={total} />
        <Content
          allNotifications={allNotifications}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          notificationsLoading={notificationsPending}
          onClose={onClose}
        />
      </div>
    </CatPopover>
  );
}

export default withDialogWrapper(NotificationPopover);
