import { CATEGORY_PLAN_NOT_REQUIRED_DETAIL } from 'routes/constant-route';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const Categories = delayedLazy(
  () => import('views/AssetConfiguration/Categories/Categories/Categories')
);
const CategoryTasks = delayedLazy(
  () => import('views/AssetConfiguration/CategoryTasks/CategoryTasks')
);
const FormList = delayedLazy(() => import('views/AssetConfiguration/Forms/List/FormList'));
const FormBuilder = delayedLazy(
  () => import('views/AssetConfiguration/Forms/Builder/FormBuilderWrapper')
);
const CategoryProtectionRequirementDetail = delayedLazy(
  () =>
    import('views/AssetConfiguration/Categories/ProtectionRequirement/ProtectionRequirementDetail')
);
const PlanAssociationNotRequiredDetail = delayedLazy(
  () =>
    import(
      'views/AssetConfiguration/Categories/PlanAssociationNotRequired/PlanAssociationNotRequiredDetail'
    )
);

export default function renderAssetConfigurationRoutes() {
  return (
    <Route
      element={
        <RouteGuard authKey="assetConfiguration">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="asset-configuration"
    >
      <Route element={<Categories />} path="categories">
        <Route element={<CategoryProtectionRequirementDetail />} path="protection-requirement" />
        <Route
          element={<PlanAssociationNotRequiredDetail />}
          path={CATEGORY_PLAN_NOT_REQUIRED_DETAIL}
        />
      </Route>
      <Route element={<CategoryTasks />} path="category-tasks" />
      <Route element={<Outlet />} path="forms">
        <Route element={<FormList />} index />
        <Route element={<FormBuilder />} path="form-builder" />
      </Route>
      <Route element={<Navigate replace to="categories" />} index />
    </Route>
  );
}
