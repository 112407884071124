import { Button, CircularProgress, Theme, useMediaQuery } from '@borda/cat-mui';
import { CatTypography } from '@borda/cat-ui';
import { ReminderIcon } from '@borda/cat-icons';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import {
  getNotificationDisplayCount,
  useGetUnreadNotificationCountQuery
} from 'views/Notifications/queries';
import { useCatTranslation } from 'utils/localization';
import NotificationPopover from 'views/Notifications/NotificationPopover';

function NotificationButton() {
  const { t } = useCatTranslation();
  const showLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const popupState = usePopupState({ popupId: 'notification_popup', variant: 'popover' });

  const handlePopoverClose = () => {
    popupState.close();
  };

  const { data, isPending, isSuccess } = useGetUnreadNotificationCountQuery();
  const { unreadNotificationCount } = data ?? { unreadNotificationCount: 0 };

  const displayNotificationText = getNotificationDisplayCount(unreadNotificationCount);

  return (
    <>
      <Button
        className={`bg-lightGrey flex items-center gap-2 rounded-2xl normal-case ${
          showLarge ? 'justify-start' : ''
        }`}
        style={{ height: '48px', width: '100%' }}
        {...bindTrigger(popupState)}
      >
        <ReminderIcon color="red" />
        {showLarge && (
          <div className="flex w-full items-center justify-between">
            <div className="grid justify-items-start gap-0.5">
              <CatTypography variant="body2">
                {t('layout.notification.notifications')}
              </CatTypography>
            </div>
            {isSuccess && unreadNotificationCount > 0 && (
              <div className="bg-red/10 flex items-center gap-1 rounded-lg p-1">
                <div className="bg-red h-[6px] w-[6px] rounded-lg border-[0.5px] border-solid border-white" />
                <CatTypography className="text-red font-bold" variant="caption">
                  {t('layout.notification.new')}
                </CatTypography>
                <CatTypography className="text-red max-w-[28px] truncate" variant="caption">
                  {displayNotificationText}
                </CatTypography>
              </div>
            )}
            {isPending && <CircularProgress size={16} />}
          </div>
        )}
      </Button>
      <NotificationPopover
        onClose={handlePopoverClose}
        open={popupState.isOpen}
        popupState={popupState}
      />
    </>
  );
}

export default NotificationButton;
