import { CatPaper } from '@borda/cat-ui';
import { Drawer, Theme, styled, useMediaQuery } from '@borda/cat-mui';
import { catColors } from '@borda/cat-core';
import { getFilteredNavigations } from './navigationHelpers';
import { selectAllBranches, selectRtlsEnabledBranches } from 'store/slices/branches';
import {
  selectAllMainCategories,
  selectClientSettings,
  selectIsUserAuthorized,
  selectSessionUser
} from 'store/slices/session';
import { useMemo } from 'react';
import { useTypedSelector } from 'hooks';
import BordaIcon from './BordaIcon';
import MinimizedAssetImport from 'views/Asset/Assets/Import/MinimizedAssetImport/MinimizedAssetImport';
import NavbarList from './NavbarList';
import NotificationButton from './components/NotificationButton';
import ProfileButton from './components/ProfileButton';

const StyledDrawer = styled(Drawer)(() => ({
  '.MuiDrawer-paper': {
    backgroundColor: catColors.lightGrey.main,
    borderRight: 'none'
  },
  '.MuiDrawer-paperAnchorLeft': {
    background: 'transparent',
    boxShadow: 'none',
    overflow: 'inherit'
  }
}));

function NavBar() {
  const showLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    defaultMatches: null
  });

  const user = useTypedSelector(selectSessionUser);
  const mainCategories = useTypedSelector(selectAllMainCategories);
  const rtlsEnabledBranches = useTypedSelector(selectRtlsEnabledBranches);
  const branches = useTypedSelector(selectAllBranches);

  const { cyleraEnable, flowEnable } = useTypedSelector(selectClientSettings);

  const navs = useMemo(
    () =>
      getFilteredNavigations({
        branches,
        cyleraEnable,
        flowEnable,
        mainCategories,
        rtlsEnabledBranches,
        user
      }),
    [branches, cyleraEnable, flowEnable, mainCategories, rtlsEnabledBranches, user]
  );

  const authorizedForAssetImport = useTypedSelector((state) =>
    selectIsUserAuthorized(state, 'assetImport')
  );

  return (
    <StyledDrawer anchor="left" open variant="persistent">
      <CatPaper
        className="shadow-5 m-1 flex flex-col justify-between rounded-3xl px-1 pb-1 pt-3 duration-500"
        style={{
          height: 'calc(100vh - 16px)',
          width: showLarge ? '192px' : '72px'
        }}
      >
        <BordaIcon />
        <div className="grid w-full overflow-y-auto px-2 py-4">
          <div className="grid gap-8">
            {navs.map((list) => (
              <NavbarList key={list.subheader} navigation={list} />
            ))}
          </div>
        </div>
        <div className="grid w-full gap-3 pt-2">
          {authorizedForAssetImport && <MinimizedAssetImport />}
          <NotificationButton />
          <ProfileButton />
        </div>
      </CatPaper>
    </StyledDrawer>
  );
}

export default NavBar;
