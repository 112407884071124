import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import { getAppSettings } from './settings';
import { isSafari } from 'react-device-detect';

let userManager: UserManager;

export const getUserManager = async () => {
  if (!userManager) {
    const { clientId, scope, ssoUrl } = getSsoConfig();

    // Disable session monitor on safari
    // https://dev.azure.com/bordasw/Quattro/_workitems/edit/13123
    const monitorSession = !isSafari;

    const userManagerConfig: UserManagerSettings = {
      accessTokenExpiringNotificationTimeInSeconds: 60,
      authority: ssoUrl,
      client_id: clientId,
      filterProtocolClaims: true,
      loadUserInfo: true,
      monitorSession,
      post_logout_redirect_uri: `${window.location.origin}`,
      redirect_uri: `${window.location.origin}/auth/callback`,
      response_mode: 'query',
      response_type: 'code',
      scope,
      silent_redirect_uri: `${window.location.origin}/silent_renew.html`,
      staleStateAgeInSeconds: 300
      // code below uses in memory store but it requires signinsilent after normal signin flow
      // userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    };

    userManager = new UserManager(userManagerConfig);
  }

  return userManager;
};

const clientAppNames = ['quattro'];
const ssoAppName = 'quattro-sso';

interface SsoConfig {
  clientId: string;
  scope: string;
  ssoUrl: string;
}

export const getSsoUrl = () => {
  const { useMockData } = getAppSettings();
  if (useMockData) {
    return 'https://demo.duendesoftware.com';
  }

  const { origin } = window.location;
  if (origin.includes('localhost')) {
    return `https://${ssoAppName}.dev.bordatech.com`;
  }

  let ssoUrl = origin;
  clientAppNames.forEach((appName) => {
    if (origin.includes(appName)) {
      ssoUrl = origin.replace(appName, ssoAppName);
    }
  });

  return ssoUrl;
};

export const getSsoConfig = (): SsoConfig => {
  const { useMockData } = getAppSettings();
  if (useMockData) {
    return {
      clientId: 'interactive.public',
      scope: 'openid profile',
      ssoUrl: getSsoUrl()
    };
  }

  return {
    clientId: 'quattro.asset.client.web',
    scope: 'openid profile quattro.gateway.assetclient',
    ssoUrl: getSsoUrl()
  };
};
