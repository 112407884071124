import { CatCheckbox, CatTypography } from '@borda/cat-ui';
import { checkAll } from 'store/slices/notifications/list/slice';
import {
  selectIsAnyChecked,
  selectIsCheckBoxIndeterminate
} from 'store/slices/notifications/list/selector';
import { selectNotificationFilterIsRead } from 'store/slices/notifications/filter/notificationFilter';
import { useCatTranslation } from 'utils/localization';
import { useTypedDispatch, useTypedSelector } from 'hooks';
import ActionButtons from './ActionButtons/ActionButtons';
import Filter from './Filter';

type Props = {
  total: number;
};

function ActionBar(props: Props) {
  const { total } = props;

  const { t } = useCatTranslation();
  const dispatch = useTypedDispatch();

  const isFilterRead = useTypedSelector(selectNotificationFilterIsRead);

  const isAnyChecked = useTypedSelector((state) => selectIsAnyChecked(state, total));
  const isCheckboxIndeterminate = useTypedSelector((state) =>
    selectIsCheckBoxIndeterminate(state, total)
  );

  const handleSelectAll = () => {
    dispatch(checkAll());
  };

  return (
    <div className="grid gap-4">
      <Filter total={total} />
      {total !== 0 && (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <CatCheckbox
              checked={isAnyChecked && !isCheckboxIndeterminate}
              indeterminate={isAnyChecked && isCheckboxIndeterminate}
              onClick={handleSelectAll}
            />
            <CatTypography variant="body1">{t('common.select_all')}</CatTypography>
          </div>
          <ActionButtons isFilterRead={isFilterRead} total={total} />
        </div>
      )}
    </div>
  );
}

export default ActionBar;
