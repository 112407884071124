import { Branch } from 'store/slices/branches';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const TrackableDevices = delayedLazy(
  () => import('views/TagConfigurations/TrackableDevices/TrackableDevices')
);

export default function renderTagConfigurationRoutes(rtlsEnabledBranches: Branch[]) {
  return (
    <Route
      element={
        <RouteGuard authKey="tagConfiguration" hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="tag-configuration"
    >
      <Route element={<Outlet />} path="trackables">
        <Route element={<TrackableDevices />} index />
      </Route>
      <Route element={<Navigate replace to="trackables" />} index />
    </Route>
  );
}
