import {
  ACCESS_POINTS,
  ACCOUNTINFORMATION,
  ACTIONS,
  ASSETS,
  ASSET_ATTRIBUTE,
  BREAKDOWN,
  CALIBRATION,
  CATEGORIES,
  CATEGORYTASKS,
  CCTV_ADD,
  CONTRACTS,
  CYLERA_LIST,
  DESIGNSYSTEM_SHOWCASE,
  DEVELOPEROPTIONS,
  FORMS,
  FirstLevelRoute,
  LOCALIZATIONSETTINGS,
  LOCATION_ATTRIBUTE,
  LOCATION_LIST,
  MAINTENANCE,
  MAP_SETTINGS,
  MEDICAL_UNIT_MANAGEMENT,
  METRICSCONFIGURATION,
  MYASSETS,
  MYEXPORTS,
  MYREQUESTSLIST,
  NOTIFICATION_MANAGEMENT,
  PATIENTS,
  PATIENT_ATTRIBUTE,
  PLANS,
  PROTECTION_CALENDAR,
  REALTIME,
  REALTIME_MAP_PAGE,
  RETIREMENT,
  RTLS_CONFIG_PAGE,
  TASKLIST,
  TRACKABLE_LIST,
  USER_ATTRIBUTE,
  USER_DEPARTMENTS,
  USER_LIST,
  USER_NOTIFICATION_SETTINGS,
  UTILIZATION,
  WAITINGFORCONFIRMATIONLIST,
  WORKFLOWS
} from 'routes/constant-route';
import { Branch } from 'store/slices/branches';
import { MainCategory, User, isUserAuthorized } from 'store/slices/session';
import { Route } from 'components/RoutingButtons';
import { WorkType } from 'store/slices/common/types';
import {
  getConfigs,
  getDevelopmentFeaturesEnabled,
  isUserAuthorizedOnProducts
} from 'utils/settings';
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from '@borda/cat-core';

type SecondLevelNavigations = Partial<Record<FirstLevelRoute, Route[]>>;

function checkDisableByWorkType(workType: WorkType, mainCategories: MainCategory[]) {
  return isObjectNullOrEmpty(mainCategories.find((c) => c.workTypes.includes(workType)));
}

type GetNavigationsProps = {
  cyleraEnable: boolean;
  firstLevelRoute: FirstLevelRoute;
  mainCategories: MainCategory[];
  rtlsEnabledBranches: Branch[];
};

function getNavigations(props: GetNavigationsProps) {
  const { cyleraEnable, firstLevelRoute, mainCategories, rtlsEnabledBranches } = props;
  const { reporting } = getConfigs();

  const secondLevelNavigations: SecondLevelNavigations = {
    asset: [
      {
        authKey: 'assetList',
        link: ASSETS,
        text: 'assets.routes.asset_list'
      },
      {
        link: MYASSETS,
        text: 'assets.routes.my_assets'
      }
    ],
    'asset-configuration': [
      {
        authKey: 'assetConfiguration',
        link: CATEGORIES,
        text: 'categories.routes.categories'
      },
      {
        authKey: 'assetConfiguration',
        link: CATEGORYTASKS,
        text: 'categories.routes.category_tasks'
      },
      {
        authKey: 'assetConfiguration',
        link: FORMS,
        text: 'categories.routes.forms'
      }
    ],
    'asset-dashboard': [
      {
        authKey: 'assetDashboard',
        link: REALTIME,
        text: 'dashboard.routes.realtime'
      },
      {
        authKey: 'assetDashboard',
        hidden: isObjectNullOrEmpty(reporting),
        link: UTILIZATION,
        text: 'dashboard.routes.utilization'
      }
    ],
    'attribute-configuration': [
      {
        authKey: 'attributeConfiguration_Asset',
        link: ASSET_ATTRIBUTE,
        products: ['asset'],
        text: `attributes.routes.asset`
      },
      {
        authKey: 'attributeConfiguration_Patient',
        link: PATIENT_ATTRIBUTE,
        products: ['patient'],
        text: `attributes.routes.patient`
      },
      {
        authKey: 'attributeConfiguration_User',
        link: USER_ATTRIBUTE,
        text: `attributes.routes.user`
      },
      {
        authKey: 'attributeConfiguration_Location',
        link: LOCATION_ATTRIBUTE,
        text: `attributes.routes.location`
      }
    ],
    contract: [
      {
        link: CONTRACTS,
        text: 'contracts.routes.contracts'
      }
    ],
    'design-system': [
      {
        link: DESIGNSYSTEM_SHOWCASE,
        text: 'layout.design_system'
      }
    ],
    integration: [
      {
        hidden: !cyleraEnable,
        link: CYLERA_LIST,
        text: 'integrations.routes.cylera'
      },
      {
        hidden: isArrayNullOrEmpty(rtlsEnabledBranches),
        link: RTLS_CONFIG_PAGE,
        text: 'integrations.routes.rtls'
      }
    ],
    location: [
      {
        authKey: 'locationManagement',
        link: LOCATION_LIST,
        text: 'locations.routes.locations'
      },
      {
        authKey: 'locationManagement',
        hidden: isArrayNullOrEmpty(rtlsEnabledBranches),
        link: ACCESS_POINTS,
        text: 'locations.routes.access_points'
      },
      {
        authKey: 'locationManagement',
        hidden: isArrayNullOrEmpty(rtlsEnabledBranches),
        link: CCTV_ADD,
        text: 'locations.routes.cctv'
      },
      {
        authKey: 'locationManagement',
        hidden: isArrayNullOrEmpty(rtlsEnabledBranches),
        link: MAP_SETTINGS,
        text: 'locations.routes.map_settings'
      }
    ],
    'medical-units': [
      {
        link: MEDICAL_UNIT_MANAGEMENT,
        text: 'medical_units.routes.medical_units'
      }
    ],
    'metrics-configuration': [
      {
        link: METRICSCONFIGURATION,
        text: 'metrics_configuration.routes.metric_configuration'
      }
    ],
    notification: [
      {
        authKey: 'notificationConfiguration',
        link: NOTIFICATION_MANAGEMENT,
        text: 'notifications.routes.system_notifications'
      }
    ],
    patient: [
      {
        link: PATIENTS,
        text: 'patients.patient_list.patient_list_title'
      }
    ],
    plan: [
      {
        link: PROTECTION_CALENDAR,
        text: 'plans.routes.protection_calendar'
      },
      {
        link: PLANS,
        text: 'plans.routes.plans'
      }
    ],
    'profile-settings': [
      {
        link: ACCOUNTINFORMATION,
        text: 'profile_settings.routes.account_information'
      },
      {
        link: LOCALIZATIONSETTINGS,
        text: 'profile_settings.routes.localization_settings'
      },
      {
        authKey: 'myExports',
        link: MYEXPORTS,
        products: ['asset'],
        text: 'profile_settings.routes.my_exports'
      },
      {
        authKey: 'notificationConfiguration',
        development: true,
        link: USER_NOTIFICATION_SETTINGS,
        text: 'profile_settings.routes.notification_settings'
      },
      {
        link: DEVELOPEROPTIONS,
        text: 'profile_settings.routes.developer_options'
      }
    ],
    'realtime-tracking': [
      {
        link: REALTIME_MAP_PAGE,
        text: 'realtime_map.routes.realtime_map'
      }
    ],
    request: [
      {
        link: MYREQUESTSLIST,
        text: 'tasks.list.routes.my_requests_title'
      }
    ],
    'tag-configuration': [
      {
        link: TRACKABLE_LIST,
        text: 'tag_configuration.routes.trackables'
      }
    ],
    task: [
      {
        authKey: 'wfcList',
        link: WAITINGFORCONFIRMATIONLIST,
        text: 'tasks.list.routes.waiting_for_confirmation_title'
      },
      {
        authKey: 'taskList',
        link: TASKLIST,
        text: 'tasks.list.routes.task_list_title'
      }
    ],
    'task-configuration': [
      {
        authKey: 'taskConfiguration',
        disabled: checkDisableByWorkType('breakdown', mainCategories),
        link: BREAKDOWN,
        text: 'task_configuration.routes.breakdown'
      },
      {
        authKey: 'taskConfiguration',
        disabled: checkDisableByWorkType('maintenance', mainCategories),
        link: MAINTENANCE,
        text: 'task_configuration.routes.maintenance'
      },
      {
        authKey: 'taskConfiguration',
        disabled: checkDisableByWorkType('calibration', mainCategories),
        link: CALIBRATION,
        text: 'task_configuration.routes.calibration'
      },
      {
        authKey: 'taskConfiguration',
        disabled: true,
        link: RETIREMENT,
        text: 'task_configuration.routes.retirement'
      }
    ],
    user: [
      {
        link: USER_LIST,
        text: 'users.routes.user_list'
      },
      {
        link: USER_DEPARTMENTS,
        products: ['asset'],
        text: 'users.routes.departments'
      },
      {
        disabled: true,
        link: '#',
        text: 'users.routes.ad_sync'
      }
    ],
    workflow: [
      {
        link: WORKFLOWS,
        text: 'workflows.routes.workflow_list'
      },
      {
        link: ACTIONS,
        text: 'workflows.routes.actions'
      }
    ]
  };
  return secondLevelNavigations[firstLevelRoute];
}

type GetSecondLevelRoutesProps = {
  cyleraEnable: boolean;
  firstLevelRoute: FirstLevelRoute;
  mainCategories: MainCategory[];
  rtlsEnabledBranches: Branch[];
  user: User;
  userBranches: Branch[];
};
export const getSecondLevelRoutes = (props: GetSecondLevelRoutesProps) => {
  const { cyleraEnable, firstLevelRoute, mainCategories, rtlsEnabledBranches, user, userBranches } =
    props;
  const developmentFeaturesEnabled = getDevelopmentFeaturesEnabled();

  const navigations = getNavigations({
    cyleraEnable,
    firstLevelRoute,
    mainCategories,
    rtlsEnabledBranches
  });

  return navigations.filter((navigationItem) => {
    if (navigationItem.hidden) {
      return false;
    }

    if (!developmentFeaturesEnabled && navigationItem.development) {
      return false;
    }

    if (!isUserAuthorizedOnProducts(navigationItem.products, userBranches)) {
      return false;
    }

    if (!isUserAuthorized(user, navigationItem.authKey)) {
      return false;
    }

    return true;
  });
};
