import { ASSET_DETAIL } from 'routes/constant-route';
import { CatChip } from '@borda/cat-ui';
import { Divider, Toolbar } from '@borda/cat-mui';
import { Route } from '../RoutingButtons';
import { SearchIcon } from '@borda/cat-icons';
import { isMacOs } from 'react-device-detect';
import { selectIsUserAuthorized } from 'store/slices/session';
import { useCallback, useEffect } from 'react';
import { useCatNavigate } from '@borda/cat-core';
import { useCatTranslation } from 'utils/localization';
import { useTypedSelector } from 'hooks';
import AssetImportDialog from 'views/Asset/Assets/Import/AssetImportDialog';
import AssetSearchModal from './Search/AssetSearchModal';
import RoutingButtons from 'components/RoutingButtons';
import clsx from 'clsx';

type Props = {
  assetSearchDialogOpen?: boolean;
  className?: string;
  routes?: Route[];
  toggleAssetSearchDialog?: (status?: boolean) => void;
};

function TopBar(props: Props) {
  const { t } = useCatTranslation();
  const navigate = useCatNavigate();
  const { assetSearchDialogOpen, className, routes, toggleAssetSearchDialog, ...rest } = props;

  const handleKeydown = useCallback(
    (e: any) => {
      if (e.code === 'KeyF' && e.altKey) {
        toggleAssetSearchDialog(true);
        e.preventDefault();
      }
    },
    [toggleAssetSearchDialog]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);

  const openSearchModal = () => {
    toggleAssetSearchDialog();
  };

  const onAssetSelect = async (assetId: string) => {
    navigate(ASSET_DETAIL.replace(':id', assetId));
    toggleAssetSearchDialog();
  };

  const authorizedForAssetImport = useTypedSelector((state) =>
    selectIsUserAuthorized(state, 'assetImport')
  );

  return (
    <>
      <div {...rest} className={clsx('w-auto bg-transparent shadow-none', className)}>
        <Toolbar
          className="border-b-darkGrey/10 bg-lightGrey min-h-[56px] justify-between border-b"
          disableGutters
        >
          <div className="flex">
            <div className="flex items-center">
              <CatChip
                color="blue"
                icon={<SearchIcon color="blue" contained={false} hoverable={false} />}
                label={
                  <div className="flex items-center">
                    {t('layout.content_layout.search.search')}
                    <CatChip
                      className="ml-2 cursor-pointer"
                      color="darkGrey"
                      label={isMacOs ? 'opt + F' : 'Alt + F'}
                      size="small"
                    />
                  </div>
                }
                onClick={openSearchModal}
                sx={{
                  '& .MuiChip-labelMedium': {
                    paddingRight: '4px'
                  }
                }}
                variant="outlined"
              />
            </div>
            <div className="mx-2 my-2 flex">
              <Divider flexItem orientation="vertical" />
            </div>
            {routes && <RoutingButtons routes={routes} />}
          </div>
        </Toolbar>
      </div>
      {authorizedForAssetImport && <AssetImportDialog />}
      <AssetSearchModal
        onAssetSelect={onAssetSelect}
        onClose={() => toggleAssetSearchDialog()}
        open={assetSearchDialogOpen}
      />
    </>
  );
}

export default TopBar;
