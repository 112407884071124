import { ACCOUNTINFORMATION } from 'routes/constant-route';
import { Button, Theme, Typography, useMediaQuery } from '@borda/cat-mui';
import { NavLink } from 'react-router-dom';
import { selectSessionUser } from '../../../store/slices/session';
import { useTypedSelector } from 'hooks';
import AvatarItem from 'views/UserManagement/Users/UserSelector/UserSelectorItem/AvatarItem';
import React from 'react';

function ProfileButton() {
  const user = useTypedSelector(selectSessionUser);
  const showLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <NavLink className="w-full" to={ACCOUNTINFORMATION}>
      <Button
        className={`bg-lightGrey flex items-center rounded-2xl normal-case ${
          showLarge ? 'justify-start' : 'justify-center'
        }`}
        component={Button}
        style={{ height: '48px', width: '100%' }}
      >
        <AvatarItem color="darkGrey" size="medium" user={user} />
        {showLarge && (
          <div className="grid pl-2" title={`${user?.firstName} ${user?.lastName}`}>
            <Typography
              className="truncate"
              variant="body2"
            >{`${user?.firstName} ${user?.lastName}`}</Typography>
          </div>
        )}
      </Button>
    </NavLink>
  );
}

export default ProfileButton;
